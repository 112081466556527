var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-box"},[_c('van-nav-bar',{attrs:{"title":"欢迎来到IT内推圈"}}),_c('div',{staticClass:"login-con"},[_vm._m(0),(!_vm.goBing)?_c('p',{staticClass:"welcome"},[_vm._v("欢迎来到内推圈，请登录")]):_vm._e(),(!_vm.goBing)?_c('van-form',{staticClass:"login-form",on:{"submit":_vm.login}},[_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"name":"手机号","clearable":"","required":"","maxlength":"11","type":"number","placeholder":"输入手机号","rules":[
            { required: true },
            {
              validator: _vm.isPhoneNum,
              message: '请输入正确的手机号',
              trigger: 'onBlur',
            },
          ]},model:{value:(_vm.userForm.mobile),callback:function ($$v) {_vm.$set(_vm.userForm, "mobile", $$v)},expression:"userForm.mobile"}})],1),_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"clearable":"","required":"","placeholder":"输入验证码","maxlength":6,"rules":[{ required: true }]},model:{value:(_vm.userForm.captcha),callback:function ($$v) {_vm.$set(_vm.userForm, "captcha", $$v)},expression:"userForm.captcha"}}),_c('span',{class:{ 'no-style': _vm.intervalId },on:{"click":_vm.sendPhoneVerifyRegist}},[_vm._v(_vm._s(_vm.sendNotice))])],1),_c('div',{staticClass:"btn-box"},[_c('van-button',{attrs:{"round":"","block":"","type":"info"}},[_vm._v("登录")]),_c('van-button',{staticStyle:{"margin-top":"10px"},attrs:{"round":"","block":"","plain":"","type":"info"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/register')}}},[_vm._v("注册")])],1)]):_vm._e(),(_vm.isWx && !_vm.goBing)?_c('div',{staticClass:"wx-login"},[_c('img',{attrs:{"src":require("../../assets/login/wx.png"),"alt":""},on:{"click":_vm.wxLogin}}),_c('span',[_vm._v("微信登录")])]):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box"},[_c('img',{attrs:{"src":require("../../assets/login/logo.png"),"alt":""}})])
}]

export { render, staticRenderFns }