<template>
  <div class="login-box">
    <van-nav-bar title="欢迎来到IT内推圈" />
    <div class="login-con">
      <div class="icon-box">
        <img src="../../assets/login/logo.png" alt />
      </div>
      <p class="welcome" v-if="!goBing">欢迎来到内推圈，请登录</p>
      <van-form v-if="!goBing" class="login-form" @submit="login">
        <div class="filed-box">
          <van-field
            v-model="userForm.mobile"
            name="手机号"
            clearable
            required
            maxlength="11"
            type="number"
            placeholder="输入手机号"
            :rules="[
              { required: true },
              {
                validator: isPhoneNum,
                message: '请输入正确的手机号',
                trigger: 'onBlur',
              },
            ]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.captcha"
            clearable
            required
            placeholder="输入验证码"
            :maxlength="6"
            :rules="[{ required: true }]"
          />
          <span :class="{ 'no-style': intervalId }" @click="sendPhoneVerifyRegist">{{ sendNotice }}</span>
        </div>
        <!-- <div class="filed-box">
          <van-field
            v-model="userForm.imageCaptcha"
            name="图形验证码"
            clearable
            required
            placeholder="输入图形验证码"
            :rules="[{ required: true }]"
          />
          <span>
            <img v-if="imgVisible" :src="imageCaptchaUrl" @click="init()" />
          </span>
        </div>-->
        <div class="btn-box">
          <van-button round block type="info">登录</van-button>
          <van-button
            style="margin-top: 10px"
            round
            block
            plain
            type="info"
            @click.prevent="$router.push('/register')"
          >注册</van-button>
        </div>
      </van-form>
      <div class="wx-login" v-if="isWx && !goBing">
        <img src="../../assets/login/wx.png" alt @click="wxLogin" />
        <span>微信登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import { isPhoneNum } from '../../utils/tool';
import { mapMutations } from 'vuex';
import { Base64 } from 'js-base64';
export default {
  name: 'Login',
  data() {
    return {
      userForm: {
        mobile: null, //手机号,自动等于用户名
        captcha: null, //手机验证码
        grantType: 'sms', //验证方式
        imageCaptcha: null, //图形验证码
        captchaHashing: null, //图形验证码hash值(从cookie中取)
        userType: 'COMPANY', //写死
      },
      bingForm: {
        mobile: null, //手机号,自动等于用户名
        kaptcha: null, //手机验证码
      },
      isWx: false,
      goBing: false,
      intervalId: null,
      sendNotice: '获取验证码',
      accept: '',
      imgVisible: true,
      imageCaptchaUrl: null,
      smsTemplateType: 'LOGIN', //验证类型("REGIST"|"LOGIN")
      isPhoneNum: isPhoneNum,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.isWx = /MicroMessenger/i.test(window.navigator.userAgent);
  },
  methods: {
    ...mapMutations('user', ['SET_ISLOGIN']),
    ...mapMutations('app', ['SET_TABBARACTIVE']),
    init() {
      // 绑定hash
      this.bindCaptchaHashing();
      // 刷新验证码图片
      this.refreshImageCaptcha();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 刷新验证码
    async refreshImageCaptcha() {
      this.imgVisible = false;
      await this.$nextTick(() => {
        this.imgVisible = true;
      });
    },
    //将captchaHashing绑定到form上.
    async bindCaptchaHashing() {
      var uuid = Math.random();
      this.imageCaptchaUrl = `${process.env.VUE_APP_BASE_URL}/selfhelpOfficialAccounts/captcha.png?id=${uuid}`;
      var captchaHashing = uuid;
      this.userForm.captchaHashing =
        captchaHashing;
    },
    goBingConfirm() {
      this.$http
        .get('/selfhelpOfficialAccounts/wechat/binding', {
          code: this.codeObj.code,
          state: this.codeObj.state,
          ...this.bingForm,
        })
        .then(async (res) => {
          if (res.code === 200) {
            this.$toast('绑定成功，跳转中!');
            this.loginSuccess(res);
          } else {
            this.$toast(res.message || '绑定失败!');
            this.$router.push({
              path: '/register',
              query: { mobile: this.bingForm.mobile },
            });
          }
        });
    },
    onConfirm(item) {
      this.userForm.gender = item.value;
      this.showPicker = false;
    },
    //发送手机验证码
    sendPhoneVerifyRegist() {
      // 未到60s
      if (this.intervalId) {
        return;
      }
      // 校验手机号是否正确.
      if (!isPhoneNum(this.userForm.mobile)) {
        this.$toast('请输入正确的手机号!');
        return;
      }
      // 发送请求,获得手机验证码
      this.$http
        .post('/selfhelpOfficialAccounts/message/sms/send', {
          mobile: this.userForm.mobile,
          smsTemplateType: this.smsTemplateType,
        })
        .then((res) => {
          if (res.code === 200) {
            this.downTime();
            this.$toast('验证短信发送成功!');
          } else {
            this.$toast(res.message || '发送失败!');
          }
        });
    },
    // 倒记时60秒
    downTime() {
      if (this.intervalId != null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      var time = 60;
      this.intervalId = setInterval(() => {
        time--;
        this.sendNotice = `再次发送(${time}s)`;
        if (time <= 0) {
          clearInterval(this.intervalId);
          this.intervalId = null;
          this.sendNotice = `发送验证码`;
        }
      }, 1000);
    },
    login() {
      // if (!this.accept)
      //   return this.$toast("请阅读并同意《内推圈注册协议》!");
      if (this.userForm.password) {
        this.userForm.password = Base64.encode(
          `${this.userForm.username}:${this.userForm.password}`
        );
      }
      this.$http
        .post('/selfhelpOfficialAccounts/oauth2/token', this.userForm)
        .then(async (res) => {
          if (res.code === 200) {
            // this.$toast('登录成功，跳转中!');
            this.loginSuccess(res);
          } else {
            this.$toast(res.message || '登录失败!');
          }
        });
    },
    getCode() {
      let url = location.search;
      let theRequest = new Object();
      if (url.indexOf('?') != -1) {
        let str = url.substr(1);
        let strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
        }
      }
      return theRequest;
    },
    delCode() {
      let url = location;
      url.search = '';
    },
    async loginSuccess(res) {
      let token = res.data.accessToken;
      localStorage.setItem('accessToken', token);
      this.SET_ISLOGIN(true);
      let dictionariesBack = await this.$http.get(
        '/selfhelpOfficialAccounts/dictionaries'
      );
      if (dictionariesBack.code === 200) {
        localStorage.setItem(
          'dictionaries',
          JSON.stringify(dictionariesBack.data)
        );
      }
      let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
      if (back.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(back.data));
        let urlPath = null;
        switch (back.data.approveCodeEnum) {
          case 'UN_LINK':
            urlPath = '/noLegalize';
            break;
          case 'APPROVE_FAIL':
            urlPath = '/noPassAudit';
            break;
          case 'SECONED_WAITTING':
            urlPath = '/auditing';
            break;
          case 'WAITING':
            urlPath = '/auditing';
            break;
          case 'APPROVE_PASS':
            urlPath = '/passAudit';
            break;
          case 'PROCESS_DONE':
            urlPath = '/positionMgt/index';
            break;
          case 'COMPANY_STOP':
            urlPath = '/login';
            localStorage.setItem('userInfo', '');
            localStorage.setItem('accessToken', '');
            this.$toast('公司被禁用');
            break;
          case 'STOP':
            urlPath = '/login';
            localStorage.setItem('userInfo', '');
            localStorage.setItem('accessToken', '');
            this.$toast('账号禁用');
            break;
        }
        // 登录成功后跳转到主页
        if (back.data.approveCodeEnum === 'PROCESS_DONE') {
          this.SET_TABBARACTIVE(5);
        }
        this.$router.push({ path: urlPath });
      }
    },
    wxLogin() {
      let location = encodeURIComponent(
        window.location.origin + '/#/wxlogin'
      );
      //生产环境的appid
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4dc6096bf8c62a21&redirect_uri=${location}&response_type=code&scope=snsapi_userinfo&state=123&#wechat_redirect`;
      //开发环境的appid
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6d089cfbe6d85f15&redirect_uri=${location}&response_type=code&scope=snsapi_userinfo&state=123&#wechat_redirect`;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .login-con {
    flex: 1;
    overflow-y: auto;
    .icon-box {
      margin-top: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 30px;
      }
      img:first-child {
        width: 180px;
      }
      span {
        width: 1px;
        height: 20px;
        background: #e5e5e5;
        margin: 0 10px;
      }
    }
    .welcome {
      font-size: 21px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin: 40px 0;
      text-align: center;
    }
    .van-cell {
      padding: 10px 16px;
      flex: 1;
      border-radius: 32px;
      background: #f7f7f7;
    }
    .van-cell::after {
      border-bottom: none;
    }
    .van-cell--required::before {
      display: none;
    }
    .filed-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 20px;
      background: #f7f7f7;
      border-radius: 24px;
      img {
        width: 77px;
        height: 23px;
      }
      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #206cfe;
        margin: 0 16px;
        text-align: left;
      }
      .no-style {
        color: #cccccc;
      }
    }
    .btn-box {
      margin-top: 30px;
      padding: 0 20px;
    }
    .radio-box {
      margin: 20px;
      ::v-deep .van-radio__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .bing-form {
      margin-top: 60px;
    }
    .wx-login {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 47px;
        width: 47px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
